import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html{
    scroll-behavior: smooth;
  }
	* {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
  }

	body {
		background: #f8faf9;
		-webkit-font-smoothing: antialiased;
	}

	body, input, button {
		font: 16px 'Nunito';
	}
`;
