import styled from 'styled-components';

import { createBreakpoint } from 'styled-components-breakpoint';

import whoImg from '../../assets/imagens/who.svg';

const points = {
  md: 1100,
  sm: 1015,
  xsm: 980,
  xxsm: 940,
  xxxsm: 890,
  xxxxsm: 770,
};

const breakPoints = createBreakpoint(points);

// img acc - ok
// valores - diminuir o espaço do menu - ok
// talvez o mapa com o menu - ok
// img vamos começar
// img Home

export const Header = styled.div`
  background: #f8faf9;
  z-index: 2;
  height: 110px;
  position: fixed;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);

  flex-direction: column;
  ${breakPoints('xxxsm')`
    flex-direction: row;
  `}

  img {
    padding: 10px;
  }
`;

export const Links = styled.div`
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${breakPoints('xsm')`
    margin-right: 80px;
  `}
`;

export const Link = styled.a`
  margin: 0px 8px;
  ${breakPoints('xsm')`
    margin: 0px 24px;
  `}

  text-decoration: none;
  font-style: normal;

  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.1em;

  font-weight: ${props => (props.active ? 800 : 600)};

  color: ${props => (props.active ? '#42ac6c' : '#333333')};

  border-bottom: ${props => (props.active ? '3px' : '0px')} solid #42ac6c;
  padding-bottom: 4px;
`;

export const Home = styled.div`
  padding-top: 55px;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    width: 323px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 44px;
    text-transform: uppercase;

    /* green */

    color: #42ac6c;
  }

  p {
    width: 351px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    /* Gray 2 */

    color: #4f4f4f;
  }

  a {
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;

    background: #0c5244;
    border-radius: 10px;

    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* white */

    color: #ffffff;
  }

  img {
    margin-left: 55px;
    display: none;
    ${breakPoints('xxsm')`
      display: block;
    `}
  }
`;

export const Who = styled.div`
  display: flex;
  padding: 60px;
  width: 100%;
  background: #0c5244;
  align-items: center;

  padding-left: 60px;
  padding-right: 60px;
  ${breakPoints('xxsm')`
    justify-content: flex-end;
    padding-right: 0px;
  `}
  flex-direction: row;

  div {
    div {
      width: 56px;
      border: 2px solid #ffffff;
      border-radius: 8px;
      margin-bottom: 16px;
    }
  }

  h1 {
    margin-bottom: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */

    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* white */

    color: #ffffff;
  }

  p {
    max-width: 639px;
    width: 100%;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */

    text-align: justify;

    /* white */

    color: #ffffff;
  }
`;

export const ImgDiv = styled.div`
  display: none;
  ${breakPoints('xxsm')`
    display: block;
  `}
  width: 716px;
  height: 500px;
  right: 0;
  background: linear-gradient(90deg, #0c5244 0%, rgba(12, 82, 68, 0) 100%),
    url(${whoImg});
  background-blend-mode: normal, luminosity;
  justify-self: flex-end;
`;

export const Values = styled.div`
  padding: 130px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* green */

    color: #42ac6c;
  }

  hr {
    /* green */
    margin-bottom: 64px;
    border: 2px solid #42ac6c;
    width: 56px;
    border-radius: 8px;
  }

  div {
    width: 100%;
    max-width: 1001px;
    display: flex;
    flex-direction: column;
    ${breakPoints('xxxxsm')`
      flex-direction: row;
    `}

    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 300px;
      height: 309px;
      background: #ffffff;
      border: 1px solid rgba(171, 171, 171, 0.3);
      box-sizing: border-box;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      h3 {
        padding-top: 32px;
        font-family: Nunito;
        font-style: normal;
        font-weight: 800;
        font-size: 22px;
        line-height: 30px;
        /* identical to box height */

        text-align: center;
        text-transform: uppercase;

        /* black */

        color: #333332;
      }

      p {
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 180%;
        /* or 25px */

        text-align: center;

        /* black */

        color: #333332;
      }
    }
  }
`;

export const Jobs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 130px 0;
  background: #0c5244;
  border: 1px solid rgba(171, 171, 171, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    /* green */

    color: #fff;
  }

  hr {
    /* green */
    margin-bottom: 64px;
    border: 2px solid #fff;
    width: 56px;
    border-radius: 8px;
  }

  img {
    display: none;
    ${breakPoints('md')`
      display: block;
    `}
  }
`;

export const JobView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    margin-left: 65px;
  }
`;

export const Start = styled.div`
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 49px;
    text-transform: uppercase;

    /* dark-green */

    color: #0c5244;
  }

  p {
    width: 348px;
    height: 58px;

    /* body-text */

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 180%;
    /* or 29px */
  }

  a {
    margin-top: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 30px;

    background: #f8faf9;
    border-radius: 10px;

    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    /* white */

    color: #42ac6c;
    border: 1px solid #42ac6c;
  }

  img {
    margin-left: 100px;
    display: none;
    ${breakPoints('xxsm')`
      display: block;
    `}
  }
`;

export const Footer = styled.footer`
  display: flex;
  background: #0c5244;
  width: 100%;
  height: 450px;

  align-items: center;
  justify-content: center;
  flex-direction: row;

  h1 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    text-transform: uppercase;

    color: #ffffff;
  }
`;

export const Contato = styled.div`
  display: flex;
  height: 347px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  ${breakPoints('xsm')`
    align-items: baseline;
  `}

  h1 {
    margin-bottom: 16px;
    margin-top: -46px;
    text-align: center;
    ${breakPoints('xsm')`
      text-align: left;
    `}
  }
`;

export const Persons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`;

export const PersonDiv = styled.div`
  background: linear-gradient(
    180deg,
    rgba(66, 172, 108, 0.3) 0%,
    rgba(66, 172, 108, 0) 100%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;

  &:first-child {
    margin-right: 16px;
  }

  h2 {
    opacity: 1;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    text-transform: uppercase;

    color: #eab600;
  }

  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #ffffff;
  }
`;

export const MapDiv = styled.div`
  display: none;
  ${breakPoints('xsm')`
    display: block;
  `}
`;
