import React from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type GenericObject = { [key: string]: any };

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.3);',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    open: {
      borderBottomWidth: 3,
      borderBottomColor: '#ff0000',
      background:
        'linear-gradient(90deg, #083930 64.29%, rgba(8, 57, 48, 0) 100%)',
    },
    close: {
      background: '#0C5244',
      borderBottomWidth: 3,
      borderBottomColor: '#ff0000',
    },
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    normalText: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.pxToRem(25),
      padding: 12,
      color: '#fff',
    },
    boldText: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.pxToRem(25),
      color: '#fff',
    },
  }),
);

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState<string | false>('panel0');
  const classes = useStyles();
  const handleChange = (panel: string) => (
    event: React.ChangeEvent<GenericObject>,
    newExpanded: boolean,
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ zIndex: 1 }}>
      <Accordion
        square
        expanded={expanded === 'panel1'}
        className={expanded === 'panel1' ? classes.open : classes.close}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.boldText}>
            ASSESSORIA EMPRESARIAL
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.normalText}>
            <li> Elaboração de plano de negócios e reestruturação </li>
            <li> Logística de Documentação / Gestão Documental </li>
            <li>
              Levantamento do ponto de equilíbrio, margem de contribuição e
              outros indicadores
            </li>
            <li> Assessoria na Implantação de sistemas informatizados </li>
            <li> Treinamentos Técnicos </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel2'}
        className={expanded === 'panel2' ? classes.open : classes.close}
        onChange={handleChange('panel2')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography className={classes.boldText}>
            ASSESSORIA CONTÁBIL
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.normalText}>
            <li> Escrituração contábil </li>
            <li> Preparação e revisão de declarações acessórias </li>
            <li> Emissão de balancetes e demonstrações financeiras </li>
            <li> Análise de balanço </li>
            <li> Assessoria em assuntos contábeis </li>
            <li> Assessoria na implantação de sistemas contábeis </li>
            <li>
              Preparação contábil e fiscal para atendimento a auditorias
              externas
            </li>
            <li> Apoio no atendimento a auditorias externas </li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel3'}
        className={expanded === 'panel3' ? classes.open : classes.close}
        onChange={handleChange('panel3')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography className={classes.boldText}>
            ASSESSORIA TRIBUTÁRIA
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.normalText}>
            <li> Escrituração fiscal </li>
            <li> Preparação de livros fiscais </li>
            <li> Preparação e revisão de declarações acessórias </li>
            <li> Apuração de tributos </li>
            <li> Revisão de tributos </li>
            <li> Assessoria em questões tributárias </li>
            <li> Planejamento tributário </li>
            <li> Análise para enquadramento de Regime </li>
          </ul>
        </AccordionDetails>
      </Accordion>

      <Accordion
        square
        expanded={expanded === 'panel4'}
        className={expanded === 'panel4' ? classes.open : classes.close}
        onChange={handleChange('panel4')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography className={classes.boldText}>
            ASSESSORIA TRABALHISTA
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.normalText}>
            <li>Elaboração da folha de pagamento</li>
            <li> Preenchimento e revisão de declarações acessórias</li>
            <li> Administração de benefícios</li>
            <li> Assessoria em questões trabalhistas</li>
            <li> Auditoria de procedimentos e rotinas trabalhistas</li>
            <li> Assessoria na implantação de sistemas de Recursos Humanos</li>
            <li> Análise de folha de pagamento</li>
          </ul>
        </AccordionDetails>
      </Accordion>
      <Accordion
        square
        expanded={expanded === 'panel5'}
        className={expanded === 'panel5' ? classes.open : classes.close}
        onChange={handleChange('panel5')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography className={classes.boldText}>
            SOCIETÁRIO – LEGALIZAÇÃO DE EMPRESAS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul className={classes.normalText}>
            <li> Abertura e encerramento de empresas </li>
            <li>
              Assessoria em reestruturações societárias(fusão, cisão e
              incorporação)
            </li>
            <li> Assessoria na regularização de empresas</li>
            <li>
              Serviços relativos a buscas, registros e inscrição em órgãos
              públicos
            </li>
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
