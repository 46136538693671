/* eslint-disable no-param-reassign */
import React, { useRef, useState } from 'react';

import { Viewport } from 'react-is-in-viewport';

import Accordion from '../../components/Accordion';

import {
  Header,
  Links,
  Link,
  Home,
  Who,
  Values,
  Jobs,
  ImgDiv,
  JobView,
  Start,
  Footer,
  Persons,
  PersonDiv,
  Contato,
  MapDiv,
} from './styles';

import logo from '../../assets/imagens/logo.svg';
import home from '../../assets/imagens/home.svg';
import excelencia from '../../assets/imagens/excelencia.svg';
import atendimento from '../../assets/imagens/atendimento.svg';
import pessoas from '../../assets/imagens/pessoas.svg';
import fazemos from '../../assets/imagens/fazemos.svg';
import start from '../../assets/imagens/start.svg';
import linkedin from '../../assets/imagens/linkedin.svg';
import instagram from '../../assets/imagens/instagram.svg';
import facebook from '../../assets/imagens/facebook.svg';

const Dashboard: React.FC = () => {
  const [isActive, setIsActive] = useState([true, false, false, false, false]);
  // home, who, value, do, contact
  const homeRef = useRef<HTMLDivElement>(document.createElement('div'));
  const whoRef = useRef<HTMLDivElement>(document.createElement('div'));
  const valueRef = useRef<HTMLDivElement>(document.createElement('div'));
  const doRef = useRef<HTMLDivElement>(document.createElement('div'));
  const contactRef = useRef<HTMLDivElement>(document.createElement('div'));

  const executeScroll = (element: React.MutableRefObject<HTMLDivElement>) =>
    element.current.scrollIntoView();

  const handleScrollChange = (index: number) => {
    console.log('a');
    const valores = isActive;
    for (let i = 0; i < valores.length; i += 1) {
      valores[i] = false;
    }
    valores[index] = true;
    setIsActive([...valores]);
  };

  const handleExitScroll = (index: number) => {
    const valores = isActive;
    valores[index] = false;
    setIsActive([...valores]);
  };
  console.log(isActive);

  return (
    <>
      <Header>
        <>
          <img src={logo} alt="Logo Talcec" />
        </>
        <Links>
          <Link
            onClick={() => {
              executeScroll(homeRef);
            }}
            active={isActive[0]}
            href="#home"
          >
            HOME
          </Link>
          <Link
            onClick={() => {
              executeScroll(whoRef);
            }}
            active={isActive[1]}
            href="#who"
          >
            QUEM SOMOS
          </Link>
          <Link
            onClick={() => {
              executeScroll(valueRef);
            }}
            active={isActive[2]}
            href="#values"
          >
            NOSSOS VALORES
          </Link>
          <Link
            onClick={() => {
              executeScroll(doRef);
            }}
            active={isActive[3]}
            href="#jobs"
          >
            O QUE FAZEMOS
          </Link>
          <Link
            onClick={() => {
              executeScroll(contactRef);
            }}
            active={isActive[4]}
            href="#contact"
          >
            CONTATO
          </Link>
        </Links>
      </Header>
      <Home ref={homeRef}>
        <Viewport
          onEnter={() => handleScrollChange(0)}
          onLeave={() => handleExitScroll(0)}
        />
        <div>
          <h1>
            ESCRITÓRIO <br /> DE CONTABILIDADE
          </h1>
          <p>
            Somos um escritório de contabilidade completo a serviço do seu
            negócio.
            <br />
            Experimente a qualidade de nosso atendimento!
          </p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=+5511989998620&text=Olá, estou entrando em contato através do site. Gostaria de informações sobre seu serviço."
          >
            solicite uma proposta
          </a>
        </div>
        <img src={home} alt="Home" />
      </Home>
      <Who ref={whoRef}>
        <Viewport
          type="overlap"
          onEnter={() => handleScrollChange(1)}
          onLeave={() => handleExitScroll(1)}
        />
        <div>
          <h1>Quem somos</h1>
          <div />
          <p>
            Nossa missão é assessorar, prestar serviços e auxiliar no
            gerenciamento do seu negócio, disponibilizando soluções apropriadas
            para a gestão administrativa, contábil e tributária das empresas.
            <br />
            Queremos ser reconhecidos pela disponibilidade de soluções contábeis
            e empresariais, agregando valor econômico para o cliente, garantindo
            seu fortalecimento e solidez, firmando uma aliança séria e
            duradoura.
            <br /> Com profissionais capacitados e serviços altamente
            qualificados a TALCEC expande os limites da contabilidade para além
            dos números, se comprometendo em focar no sucesso de sua empresa.
          </p>
        </div>
        <ImgDiv />
      </Who>
      <Values ref={valueRef}>
        <Viewport
          type="overlap"
          onEnter={() => handleScrollChange(2)}
          onLeave={() => handleExitScroll(0)}
        />
        <h1>Nossos valores</h1>
        <hr />
        <div>
          <div>
            <img src={excelencia} alt="Excelencia" />
            <h3>Excelência</h3>
            <p>
              Entregamos o que prometemos, de forma eficiente e precisa.
              Agregamos valor para exceder as expectativas &nbsp;
            </p>
          </div>
          <div>
            <img src={atendimento} alt="Excelencia" />
            <h3>atendimento</h3>
            <p>
              A satisfação dos nossos clientes é o combustível do nosso
              crescimento. <br />
              Por isso damos total atenção ao relacionamento com os nossos
              clientes
            </p>
          </div>
          <div>
            <img src={pessoas} alt="Excelencia" />
            <h3>pessoas</h3>
            <p>
              A nossa equipe é parte fundamental do <br /> bom atendimento e da
              excelência dos <br /> serviços e, por isso, valorizamos e <br />
              investimos em pessoas
            </p>
          </div>
        </div>
      </Values>
      <Jobs ref={doRef}>
        <Viewport
          type="overlap"
          onEnter={() => handleScrollChange(3)}
          onLeave={() => handleExitScroll(3)}
        />
        <h1>O que fazemos</h1>
        <hr />
        <JobView>
          <Accordion />
          <img src={fazemos} alt="O que fazemos" />
        </JobView>
      </Jobs>
      <Start ref={contactRef}>
        <Viewport
          type="overlap"
          onEnter={() => handleScrollChange(4)}
          onLeave={() => handleExitScroll(4)}
        />
        <div>
          <h1>E ai, vamos começar?</h1>
          <p>
            Entre em contato agora conosco e entenda como podemos ajudar a sua
            empresa
          </p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://api.whatsapp.com/send?phone=+5511989998620&text=Olá, estou entrando em contato através do site. Gostaria de informações sobre seu serviço."
          >
            Entrar em contato
          </a>
        </div>
        <img src={start} alt="Vamos começar" />
      </Start>
      <Footer>
        <Contato>
          <div>
            <h1>Contato</h1>
            <Persons>
              <PersonDiv>
                <h2>Alfredo F. Diniz</h2>
                <p>Contador</p>
                <br />
                <p>(11) 98999-8620</p>
                <p>alfredo@talcec.com.br</p>
              </PersonDiv>
              <PersonDiv>
                <h2>Rita Carvalho</h2>
                <p>Especialista Tributária</p>
                <br />
                <p>(11) 95410-9921</p>
                <p>rita@talcec.com.br</p>
              </PersonDiv>
            </Persons>
          </div>
          <div>
            <h1>Redes sociais</h1>
            <div style={{ display: 'flex' }}>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/talcec-escritório-contábil-1660981bb/"
                style={{ marginRight: 16 }}
              >
                <img style={{ width: 40 }} src={linkedin} alt="Linkedin logo" />
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/talceccontabilidade/?hl=pt-br"
                style={{ marginRight: 16 }}
              >
                <img
                  style={{ width: 40 }}
                  src={instagram}
                  alt="Instagram logo"
                />
              </a>

              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/talcec/"
              >
                <img
                  style={{ width: 40 }}
                  src={facebook}
                  alt="Instagram logo"
                />
              </a>
            </div>
          </div>
        </Contato>
        <MapDiv style={{ marginLeft: 30 }}>
          <h1 style={{ marginBottom: 16 }}>Endereço</h1>
          <iframe
            title="Titulo unico"
            width="600"
            height="300"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDPNsNBxxua53d7OScFmv8me7njDzPBbSs&q=Rua+Brigadeiro+Tobias+577+Luz,Sao+Paulo+SP+01032-001"
          />
        </MapDiv>
      </Footer>
    </>
  );
};

export default Dashboard;
